<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-alert v-if="error" color="danger">{{error}}</mdb-alert>
            <mdb-row>
              <mdb-col md="8">
                <mdb-card>
                  <mdb-card-header color="unique-color-dark">{{$route.params.callsign}}</mdb-card-header>
                  <mdb-card-body>
                    <mdb-spinner v-if="!flightsloaded" />
                    <div v-else>
                      <h3>Derniers Vols</h3>
                      <mdb-tbl>
                        <mdb-tbl-head>
                          <tr>
                            <th>Take-off</th>
                            <th>Landing</th>
                            <th>Status</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th>&nbsp;</th>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                          <tr v-for="(flight,index) in flights" v-bind:key="index">
                            <td>{{formatDate(flight.takeoffdatetime)}}</td>
                            <td v-if="flight.arrivaldatetime">{{formatDate(flight.arrivaldatetime)}}</td>
                            <td v-else>--</td>
                            <td v-if="flight.iscompleted">LANDED</td>
                            <td v-else>{{flight.currentstatus}}</td>
                            <td>{{flight.departureposition}}</td>
                            <td v-if="flight.arrivalposition">{{flight.arrivalposition}}</td>
                            <td v-else>--</td>
                            <td>
                              <mdb-btn tag="a" color="mdb-color" floating size="" :title="flight.iscompleted?'REPLAY':'LIVE TRACKING'" v-on:click="openTrackingMap(flight.takeoffid)">
                                <mdb-icon icon="map" />
                              </mdb-btn>
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="4">
                <mdb-spinner v-if="!aircraftinfos" />
                <mdb-card narrow v-else>
                  <mdb-view hover cascade>
                      <mdb-card-image :src="`https://storage.googleapis.com/owg-public/go.owg.com/prod/${aircraftinfos.image}`"></mdb-card-image>
                      <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                  </mdb-view>
                  <mdb-card-body cascade>
                    <h5 class="pink-text pb-2 pt-1"><mdb-icon icon="history" /> Status</h5>
                    <mdb-card-title><strong>{{aircraftinfos.status}}</strong></mdb-card-title>
                    <mdb-card-text>
                      <mdb-tbl sm borderless>
                        <mdb-tbl-body>
                          <tr>
                            <td>Position:</td>
                            <td>{{aircraftinfos.lastseenposition}} ({{aircraftinfos.lastseenpositiondistance}})</td>
                          </tr>
                          <tr>
                            <td>Last&nbsp;seen: </td>
                            <td>{{lastseenformatted(aircraftinfos.lastseendatetime)}}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </mdb-card-text>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbCardImage,
  mdbMask,
  mdbView,
  mdbCardHeader,
  mdbCardTitle,
  mdbCardText,
  mdbTbl,
  mdbTblBody,
  mdbSpinner,
  mdbAlert,
  mdbTblHead,
  mdbBtn,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';

export default {
  name: 'documentationCenter',
  data() {
    return {
      aircraftinfos: null,
      flights: [],
      flightsloaded: false,
      isloading: true,
      error: null,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbIcon,
    mdbCardImage,
    mdbMask,
    mdbView,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardText,
    mdbTbl,
    mdbTblBody,
    mdbSpinner,
    mdbAlert,
    mdbTblHead,
    mdbBtn,
  },
  mounted() {
    this.loadAircraftInfos();
  },
  methods: {
    loadAircraftInfos() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/skytrac/aircraft-infos', { callsign: this.$route.params.callsign }).then((response) => {
        if (response.status === 'OK') {
          self.aircraftinfos = response.data;
          self.loadLastFlights();
        } else {
          self.error = response.msg;
        }
      });
    },
    lastseenformatted(thedate) {
      Moment.locale(this.$store.state.currentlang);
      return Moment(thedate).fromNow();
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    loadLastFlights() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/skytrac/last-flights-for-aircraft', { callsign: this.$route.params.callsign, nbdays: 7 }).then((response) => {
        if (response.status === 'OK') {
          self.flights = response.data;
          self.flightsloaded = true;
        } else {
          self.error = response.msg;
        }
      });
    },
    openTrackingMap(takeoffid) {
      this.error = 'Available soon!';
      window.scrollTo(0, 0);
    },
  },
};
</script>
